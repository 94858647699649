<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="#1976d2"
      size="128"
      :is-full-screen="false"
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout v-if="ServerError" wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <ServerError />
      </v-flex>
    </v-layout>
    <v-layout wrap v-else>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <v-card>
          <v-card-title class="elevation-1">
            <span>Story Head</span>
            <v-spacer></v-spacer>
            <v-flex pt-5 xl3 lg3 md3 pb-2 sm3 xs3 px-5>
              <v-dialog
                :retain-focus="false"
                v-model="dialog"
                persistent
                max-width="1000px"
                :key="dialog"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn outlined color="red" v-bind="attrs" v-on="on">
                    Add
                  </v-btn>
                </template>
                <v-card>
                  <v-form v-model="addslider">
                    <v-card-title>
                      <span class="headline">Add</span>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="12" md="12">
                            <v-text-field
                              v-model="title"
                              label="Title"
                              outlined
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="12">
                            <v-text-field
                              v-model="caption"
                              label="Caption"
                              outlined
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <ImageComp
                              :singleImage="mainImagePreview"
                              @stepper="winStepper"
                              :componentType="'mainImage'"
                              :height="'450'"
                              :width="'2500'"
                              :heading="'Upload Image (2500 X 450)'"
                            />
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="dialogclose()">
                        Close
                      </v-btn>
                      <v-btn
                        color="blue darken-1"
                        text
                        :disabled="!addslider"
                        @click="add()"
                      >
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-form>
                </v-card>
              </v-dialog>
            </v-flex>
          </v-card-title>
          <div>
            <v-container grid-list-md>
              <v-layout wrap pt-10 pb-10>
                <v-flex v-for="(item, i) in staticArray" :key="i" md3 pa-4>
                  <v-card elevation="1">
                    <div v-viewer style="display: flex;">
                      <img
                        style="
                          width: 100%;
                          height: 100%;
                          object-fit: cover;
                          cursor: pointer;
                        "
                        :src="baseURL + item.image"
                      />
                    </div>
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="#FF2323"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                    <v-layout wrap justify-center px-2>
                      <v-flex xl12 md12 sm12 xs12 v-if="item.title">
                        <span style="font-size: 13px; color: #000000">
                          {{ item.title }}
                        </span></v-flex
                      >
                      <v-flex xl12 md12 sm12 xs12 v-if="item.caption">
                        <span style="font-size: 13px; color: #000000">
                          {{ item.caption }}
                        </span></v-flex
                      >
                      <v-flex pt-5 px-4 xs6 sm6 md6 lg6 xl6 justify-center>
                        <v-dialog v-model="item.delete" max-width="600px">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              small
                              class="mr-2"
                              outlined
                              block
                              v-on="on"
                              v-bind="attrs"
                              color="red"
                            >
                              Delete
                            </v-btn>
                          </template>
                          <v-card>
                            <v-card-title
                              >Are you sure you want to delete this
                              ?</v-card-title
                            >
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                color="blue darken-1"
                                text
                                @click="item.delete = false"
                                >Cancel</v-btn
                              >
                              <v-btn
                                color="blue darken-1"
                                text
                                @click="deleteItem(item)"
                                >OK</v-btn
                              >
                              <v-spacer></v-spacer>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </v-flex>
                      <v-flex pt-5 px-4 pb-2 xs6 sm6 md6 lg6 xl6 justify-center>
                        <v-btn
                          small
                          block
                          class="mr-2"
                          outlined
                          color="green"
                          @click="editSlider(item)"
                        >
                          Edit
                        </v-btn>
                      </v-flex>
                      <br />
                    </v-layout>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-container>
          </div>
          <v-dialog v-model="editdialog" max-width="600px">
            <v-card>
              <v-card-title>
                <span class="headline">Edit Slider</span>
              </v-card-title>
              <v-card-text>
                <v-layout wrap>
                  <v-flex xs12 pt-5>
                    <v-text-field
                      outlined
                      v-model="editingitem.title"
                      label="Title"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 pt-5>
                    <v-text-field
                      outlined
                      v-model="editingitem.caption"
                      label="Caption"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 pt-5>
                    <ImageComp
                      :singleImage="editingitem.image"
                      @stepper="winStepper"
                      :height="'450'"
                      :width="'2500'"
                      :heading="'Upload Image (2500 X 450)'"
                      :componentType="'mainImage'"
                    />
                  </v-flex>
                </v-layout>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="editdialog = false">
                  Close
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="edit(editingitem._id)"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import ImageComp from "@/components/commonComponents/singleImage";
export default {
  components: {
    ImageComp,
  },
  data() {
    return {
      checkbox: true,
      ServerError: false,
      showsnackbar: false,
      editingitem: [],
      caption: "",
      file: null,
      coverFile: null,
      mainImagePreview: "",
      productImagePreview: "",
      image: "",
      mainImage: "",
      productImage: "",
      msg: null,
      pages: 0,
      Value: null,
      title: null,
      widthOfCard: "250px",
      appLoading: false,
      currentPage: 1,
      totalData: 0,
      totalRows: 0,
      dialogDelete: false,
      search: "",
      dialog: false,
      editdialog: false,
      staticArray: [],
      addslider: false,
      nameRules: [
        (v) => !!v || "Name is required",
        (v) => (v && v.length <= 15) || "Name must be less than 15 characters",
      ],
      Rules: [(value) => !!value || "Required."],
    };
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    winStepper(window_data) {
      if (window_data.type == "mainImage") {
        this.file = window_data.selectedFiles;
      }
    },
    getData() {
      this.appLoading = true;
      axios({
        url: "/story/page/get",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.staticArray = response.data.data;
          // this.title = this.staticArray.title;
          // this.mainImagePreview = this.staticArray.image;
          // this.caption=this.staticArray.caption
          this.pages = response.data.pages;
          this.totalData = response.data.count;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    add() {
      var data = {};
      data["title"] = this.title;
      data["caption"] = this.caption;
      axios({
        url: "/story/page/add",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.dialog=false;
            if (this.file) {
              this.uploadImage(response.data.id._id);
            } else {
              this.getData();
            }
            this.msg = "Added Sucessfully";
            this.showsnackbar = true;
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.$emit("stepper", {
            ServerError: true,
          });
          console.log(err);
        });
    },
    uploadImage(id) {
      let formData = new FormData();
      formData.append("photo", this.file);
      formData.append("id", id);
      axios({
        method: "POST",
        url: "/story/page/image",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.appLoading = false;
            this.showsnackbar = true;
            this.msg = "Uploaded Successfully";
            this.getData();
          } else {
            this.appLoading = false;
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    dialogclose() {
      this.link = null;
      this.dialog = false;
    },
    edit(id) {
      this.appLoading = true;
      var data = {};
      data["id"] = id;
      data["title"] = this.title;
      data["caption"] = this.caption;
      axios({
        method: "POST",
        url: "/story/page/set",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: data,
      })
        .then((response) => {
          this.appLoading = false;
          this.editdialog = false;
          if (response.data.status) {
            this.msg = "Edited Sucessfully";
            this.showsnackbar = true;
            this.dialog = false;
            if (this.file) {
              this.uploadImage(id);
            } else {
              this.getData();
            }
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    deleteItem(r) {
      var data = {};
      data["id"] = r._id;
      axios({
        url: "/story/page/remove",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.delete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    editSlider(item) {
      this.editingitem = item;
      this.editdialog = true;
    },
  },
};
</script>
